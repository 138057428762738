export const reportTypes = [
  {
    name: "Summary Report",
    to: "lauderLaw---reports--summary",
    id: 3
  },
  {
    name: "Summary W Note",
    to: "lauderLaw---reports--summaryNote",
    id: 4
  },
  {
    name: "All Collections",
    to: "lauderLaw---reports--all",
    id: 0
  },
  {
    name: "Last Note",
    to: "lauderLaw---reports--last",
    id: 1
  },
  {
    name: "Open Collections",
    to: "lauderLaw---reports--open",
    id: 2
  }
];
export const rowNumber = 500;
export const headers = [
  {
    text: "Date",
    align: "start",
    value: "Payment_Date",
    class: "secondary white--text",
    width: "10%",
    sort: (a, b) => {
      const aDate = new Date(a),
        bDate = new Date(b);
      if (aDate > bDate) {
        return 1;
      } else if (bDate > aDate) {
        return -1;
      } else {
        return 0;
      }
    }
  },
  {
    text: "Staff",
    sortable: false,
    value: "staff",
    class: "secondary white--text"
  },
  { text: "Memo", value: "Memo", class: "secondary white--text" }
];
export const headers_2 = type => {
  if (type === 3) {
    return [
      {
        text: "Property Address",
        align: "start",
        value: "Client_Address",
        class: "secondary white--text"
      },
      {
        text: "Owner",
        value: "Home_Owner",
        class: "secondary white--text"
      },
      { text: "Status", value: "Status", class: "secondary white--text" },
      {
        text: "Payments",
        value: "payAmount",
        class: "secondary white--text"
      }
    ];
  } else {
    return [
      {
        text: "Property Address",
        align: "start",
        value: "Client_Address",
        class: "secondary white--text"
      },
      {
        text: "Owner",
        value: "Home_Owner",
        class: "secondary white--text"
      },
      { text: "Status", value: "Status", class: "secondary white--text" },
      {
        text: "Payments",
        value: "payAmount",
        class: "secondary white--text"
      },
      {
        text: "Note",
        value: "Memo",
        class: "secondary white--text"
      }
    ];
  }
};
//todo::abdallah change this file
export const hasALocalPyServer = false;
