var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_2(_vm.headers),"items":_vm.summary,"items-per-page":_vm.rowNumber,"item-key":"","hide-default-footer":"","dense":"","item-class":function () { return 'custom-row'; }},on:{"click:row":_vm.transmit},scopedSlots:_vm._u([{key:"item.Client_Address",fn:function(ref){
var item = ref.item;
return [(_vm.search.started)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedHtml(_vm.search.text, item['Client_Address']))}}):_c('span',[_vm._v(_vm._s(item["Client_Address"]))])]}},{key:"item.Home_Owner",fn:function(ref){
var item = ref.item;
return [(_vm.search.started)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedHtml(_vm.search.text, item['Home_Owner']))}}):_c('span',[_vm._v(_vm._s(item["Home_Owner"]))])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(_vm.search.started)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedHtml(_vm.search.text, item['Status']))}}):_c('span',[_vm._v(_vm._s(item["Status"]))])]}},{key:"item.Memo",fn:function(ref){
var item = ref.item;
return [(_vm.search.started)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedHtml(_vm.search.text, item['Memo']))}}):_c('span',[_vm._v(_vm._s(item["Memo"]))])]}},{key:"item.payAmount",fn:function(ref){
var item = ref.item;
return [(_vm.search.started)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedHtml(_vm.search.text, item['payAmount']))}}):_c('span',[_vm._v(_vm._s(_vm._f("moneyFormat")(item["payAmount"])))])]}},{key:"footer",fn:function(){return [_c('v-toolbar',{staticClass:"secondary",attrs:{"dark":"","dense":""}},[_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Total: "),_c('v-btn',{attrs:{"color":"white","ripple":"","right":"","dark":"","text":""}},[_vm._v(_vm._s(_vm._f("moneyFormat")(_vm.payment)))])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }